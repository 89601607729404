import { useState } from 'react';
import { Image } from '@leagueplatform/genesis-commons';
import {
  Box,
  Checkbox,
  Button,
  StackLayout,
  InputStatusMessage,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import privacyIcon from 'assets/privacy.png';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { TermAndConditionsText } from 'components/term-and-conditions-text/term-and-conditions-text.component';
import { AGREEMENT_STATUS_TYPES } from 'common/constants';
import { useSetAgreementStatus } from 'hooks/use-set-agreement-status.hook';

type ReacceptTOUAgreementFormValues = {
  isTermsAccepted: boolean;
};

const IS_TERMS_ACCEPTED = 'isTermsAccepted';

export const ReacceptTOUAgreement = ({
  endReacceptanceFlow,
}: {
  endReacceptanceFlow: () => void;
}) => {
  const [showFullPageError, setShowFullPageError] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { mutateAsync: mutateAgreementStatus, isLoading } =
    useSetAgreementStatus();

  const onSubmit = async (
    { isTermsAccepted }: ReacceptTOUAgreementFormValues,
    actions: FormikHelpers<ReacceptTOUAgreementFormValues>,
  ) => {
    if (isTermsAccepted) {
      await mutateAgreementStatus(
        {
          agreementType: AGREEMENT_STATUS_TYPES.TOU,
          isAccepted: true,
        },
        {
          onSuccess: endReacceptanceFlow,
          onError: () => {
            // Display full page error message
            setShowFullPageError(true);
          },
        },
      );
    } else {
      // Display the error message if checkbox is not checked
      actions.setFieldError(
        IS_TERMS_ACCEPTED,
        formatMessage({ id: 'TERMS_REACCEPTANCE_ERROR' }),
      );
    }
  };

  return (
    <Box>
      {showFullPageError ? (
        <FullPageError
          errorType={ErrorTypes.API_CONNECTION_ERROR}
          buttonOnClick={() => setShowFullPageError(false)}
          buttonChildren={formatMessage({ id: 'RETRY' })}
        />
      ) : (
        <FullScreenContainer>
          <Box
            css={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <StackLayout
              css={{ maxWidth: '327px' }}
              horizontalAlignment="center"
            >
              <Image
                src={privacyIcon}
                alt=""
                width="138px"
                height="auto"
                position="relative"
                marginBottom="three"
              />
              <HeadingText
                level="1"
                size="xl"
                css={{ textAlign: 'center', marginBottom: '$two' }}
              >
                {formatMessage({ id: 'TERMS_REACCEPTANCE_HEADING' })}
              </HeadingText>
              <Formik
                initialValues={{ [IS_TERMS_ACCEPTED]: false }}
                onSubmit={onSubmit}
              >
                {(formProps) => (
                  <Form>
                    <StackLayout
                      horizontalAlignment="center"
                      spacing={
                        formProps.errors[IS_TERMS_ACCEPTED] ? '$two' : '$four'
                      }
                    >
                      <Box>
                        <Field
                          as={Checkbox}
                          label={<TermAndConditionsText />}
                          name={IS_TERMS_ACCEPTED}
                          id={IS_TERMS_ACCEPTED}
                          aria-describedby={`${IS_TERMS_ACCEPTED}-error`}
                        />
                        {formProps.errors[IS_TERMS_ACCEPTED] && (
                          <InputStatusMessage
                            inputStatus="error"
                            id={`${IS_TERMS_ACCEPTED}-error`}
                            css={{
                              marginTop: 0,
                              marginLeft: '$twoAndHalf',
                            }}
                          >
                            {formProps.errors[IS_TERMS_ACCEPTED]}
                          </InputStatusMessage>
                        )}
                      </Box>
                      <Button
                        loading={isLoading}
                        type="submit"
                        width={{
                          '@initial': 'hugContents',
                          '@mobile': 'fillContainer',
                        }}
                      >
                        {formatMessage({ id: 'CONTINUE' })}
                      </Button>
                    </StackLayout>
                  </Form>
                )}
              </Formik>
            </StackLayout>
          </Box>
        </FullScreenContainer>
      )}
    </Box>
  );
};
