import { useEffect } from 'react';
import { OnboardingRoutesWithProvider } from '@leagueplatform/onboarding';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';

export const FeatureHighlights = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  useEffect(() => () => {
    onComplete();
  });

  return (
    <FullScreenContainer>
      <OnboardingRoutesWithProvider
        contentfulId={import.meta.env.VITE_CONTENTFUL_ID}
        tenantId={import.meta.env.VITE_TENANT_ID}
      />
    </FullScreenContainer>
  );
};
