import { GDSDecorativeTokens } from '@leagueplatform/genesis-core';

const decorative: GDSDecorativeTokens = {
  brand: {
    primary: {
      darkest: '#222222',
      dark: '#CC4E22',
      default: '#FF612B',
      bright: '#FF8155',
      brightest: '#FFA080',
      pastel: '#FBF9F4',
    },
    secondary: {
      darkest: '#40305E',
      dark: '#422C88',
      default: '#8061BC',
      bright: '#A088CD',
      brightest: '#B3A0D7',
      pastel: '#E6DFF2',
    },
    tertiary: {
      darkest: '#40305E',
      dark: '#422C88',
      default: '#007C89',
      bright: '#A088CD',
      brightest: '#B3A0D7',
      pastel: '#E6DFF2',
    },
  },
};

export const decorativeTokens = {
  decorative,
};
