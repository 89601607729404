import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';

export const AuthSignOut = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      federated: true,
      returnTo: window.location.origin,
    });
  }, []);

  return <FullPageLoadingSpinner />;
};
