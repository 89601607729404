import League, {
  LEAGUE_MODULE_NAMES,
  ENVIRONMENT,
  LeagueConfig,
} from '@leagueplatform/core';
import { leagueCareDiscovery } from '@leagueplatform/care-discovery';
import { ASSETS_CONFIG } from 'common/asset-config';
import { AUTH0_CLIENT_CONNECTION, TENANT_ID } from 'common/constants';
import { theme } from 'theme/theme';
import { EN_STRING_OVERRIDES } from 'locales/en';
import { handleLink } from 'utils/handle-link/handle-link.util';

const params = new URLSearchParams(window.location.search);
const e2eTesting = params.get('e2e-test');

export const config: LeagueConfig = {
  core: {
    tenantId: TENANT_ID,
    auth: {
      clientOptions: {
        domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
        redirect_uri: window.location.origin,
        // if we're E2E testing we need to use league login because HSID blocks maestro
        connection:
          import.meta.env.VITE_ENV === 'staging' && e2eTesting
            ? ''
            : AUTH0_CLIENT_CONNECTION,
        // if we're on testprod we need to set an audience value
        ...(import.meta.env.VITE_AUTH0_AUDIENCE
          ? { audience: import.meta.env.VITE_AUTH0_AUDIENCE }
          : null),
      },
      redirectLoginOptions: {
        // For an understanding of why we pass this parameter see: https://everlong.atlassian.net/wiki/spaces/CHN/pages/3651668934/Passing+Portal+Names+to+upstream+HSID+Provider
        login_hint: 'careadvisorconnect',
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 0.25,
    },
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    // TODO update content URL
    contentUrl: import.meta.env.VITE_CONTENT_SERVER_URL as string,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,

    customMethods: {
      handleLink,
    },
    analytics: {
      segmentKey: import.meta.env.VITE_SEGMENT,
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
    ui: {
      theme,
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
};

// These are SDK initializers that have not yet been migrated to the new Config API.
export const initializeNonConfigSDKs = () => {
  League.overwriteRoutePaths(
    // TODO `/member` prefix should be removed when SDKs are capable of supporting custom routes
    // Some modules require the `/member` prefix for routing to work properly.
    // When adding a new route, unless your module REQUIRES the `/member` prefix, you should
    // not include it in your route.
    {
      [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
      [LEAGUE_MODULE_NAMES.healthPrograms]: '/member/health-programs',
      [LEAGUE_MODULE_NAMES.healthProgress]: '/member/health-progress',
      [LEAGUE_MODULE_NAMES.healthJourney]: '/member/health-journey',
      [LEAGUE_MODULE_NAMES.careDiscovery]: '/member/get-care',
      [LEAGUE_MODULE_NAMES.careDocuments]: '/care/documents',
      [LEAGUE_MODULE_NAMES.messaging]: '/messaging',
    },
  );

  leagueCareDiscovery.config({
    masonryAppId: {
      left: 'optum_care_homescreen_web_col1',
      right: 'optum_care_homescreen_web_col2',
    },
  });
};
