import {
  Flex,
  Box,
  genesisStyled,
  VisuallyHidden,
  useTheme,
  css,
} from '@leagueplatform/genesis-commons';
import spinner from 'assets/loading.svg';

const SpinnerPositioner = genesisStyled(Flex)(
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
);

const CircleOutline = genesisStyled(SpinnerPositioner)(
  css({
    borderWidth: '6px',
    borderStyle: 'solid',
    borderRadius: '100px',
    height: '48px',
    width: '48px',
  }),
);

const AnimatedSpinner = genesisStyled(Box)(
  css({
    height: '48px',
    width: '48px',
    backgroundImage: `url(${spinner})`,
    animation: 'rotate 1s linear infinite forwards',
    '@keyframes rotate': {
      to: {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

export const LoadingSpinner = ({ ...props }: any) => {
  const { colors } = useTheme();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box position="relative" {...props}>
      <CircleOutline
        aria-hidden="true"
        borderColor={colors.decorative.brand.primary.pastel}
      />
      <SpinnerPositioner aria-hidden="true">
        <AnimatedSpinner stroke={colors.decorative.brand.primary.default} />
      </SpinnerPositioner>
      <VisuallyHidden>Loading.</VisuallyHidden>
    </Box>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
