import {
  HeadingText,
  ParagraphText,
  Button,
  Modal,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import errorImg from 'assets/error.png';
import { BaseModalContainer } from './base-modal.component';

export const AccountDeletedModal = () => {
  const { formatMessage } = useIntl();

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'ACCOUNT_DELETED' })}
      footer={
        <Modal.Close>
          <Button
            type="submit"
            size={{
              '@initial': 'medium',
              '@mobile': 'large',
            }}
            width={{
              '@initial': 'hugContents',
              '@mobile': 'fillContainer',
            }}
            priority="primary"
            css={{ alignSelf: 'flex-end', marginBlockStart: '$two' }}
          >
            {formatMessage({ id: 'I_UNDERSTAND' })}
          </Button>
        </Modal.Close>
      }
    >
      <ImageMessage image={errorImg} imageWidth={146} css={{ flexGrow: 1 }}>
        <Modal.Title>
          <HeadingText level="2" size="lg" css={{ marginBlock: '$one' }}>
            {formatMessage({ id: 'ACCOUNT_DELETED_TITLE' })}
          </HeadingText>
        </Modal.Title>
        <ParagraphText>
          {formatMessage({ id: 'ACCOUNT_DELETED_BODY' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
