import { useCallback, useEffect, useMemo, useState } from 'react';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { UserDataModel } from 'models/user-data.model';
import { useUploadFile } from './use-upload-file.hook';

/**
 * @name useUploadProfilePhoto
 * @desc React hook to upload a file, hydrate UI with file ID (existing or uploaded), handle file size error and upload error
 * @returns {array{}} [Values, Functions]
 * @returns {string} Values.profilePhotoId: the image ID stored in local state
 * @returns {object} Values.image: metadata for display in another component
 * @returns {boolean} Values.uploadError: value to show an error modal in the profile image component
 * @returns {function} Functions.setUploadError: reset an error modal in the profile image component
 * @returns {function} Functions.uploadProfilePhoto: upload the image file, set the new file ID in local state and return the new file ID
 * @returns {function} Functions.resetProfilePhotoId: reset the image to initial state (e.g. in case of profile update error)
 */

interface Values {
  profilePhotoId: string;
  image: {
    src: string;
    width: string;
    alt: string;
  };
  uploadError?: boolean;
}

interface Functions {
  setUploadError: (value: boolean) => void;
  uploadProfilePhoto: (file: File) => Promise<string | null>;
  resetProfilePhotoId: () => void;
}

const fileSizeLimit = 1000000; // 1MB

export const useUploadProfilePhoto = (
  data: UserDataModel,
): [Values, Functions] => {
  const profilePhotoIdInitialState = data.userProfile?.avatar?.imageId || '';
  const [profilePhotoId, setProfilePhotoId] = useState(
    profilePhotoIdInitialState,
  );
  const resetProfilePhotoId = () =>
    setProfilePhotoId(profilePhotoIdInitialState);

  const [uploadError, setUploadError] = useState<boolean>(false);
  const uploadUserPhoto = useUploadFile();

  const memoizedImage = useMemo(
    () => ({
      src: profilePhotoId || '',
      width: '128px',
      alt: 'USER_PROFILE_PHOTO',
    }),
    [profilePhotoId],
  );

  useEffect(() => {
    if (data?.userProfile?.avatar?.imageId && !profilePhotoId) {
      setProfilePhotoId(data?.userProfile?.avatar?.imageId);
    }
  }, [data, profilePhotoId]);

  const uploadProfilePhoto = useCallback(
    async (file) => {
      if (file.size > fileSizeLimit) {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'FILE_TOO_LARGE_ERROR',
        });
        return null;
      }

      const newFileId = await uploadUserPhoto(file);

      if (!newFileId) {
        setUploadError(true);
        return null;
      }

      setProfilePhotoId(newFileId);
      return newFileId;
    },
    [uploadUserPhoto],
  );

  return [
    {
      profilePhotoId,
      image: memoizedImage,
      uploadError,
    },
    {
      setUploadError,
      uploadProfilePhoto,
      resetProfilePhotoId,
    },
  ];
};
