import { useMediaQuery, queryHelpers, Box } from '@leagueplatform/genesis-core';
import { BackgroundImage } from '@leagueplatform/genesis-commons';
import landingPagePhoto from 'assets/landing-page-photo.jpg';

export const LandingPageBackgroundImageSection = () => {
  const isMobile = useMediaQuery(queryHelpers.between([0, 'tablet']));

  return (
    <Box
      css={{
        height: '100vh',
        '@mobile': {
          height: '33vh',
          minHeight: '33vh',
        },
        '@mobileLandscape': {
          height: '33vh',
          minHeight: '33vh',
        },
      }}
    >
      <BackgroundImage
        alt=""
        height="100%"
        src={landingPagePhoto}
        role="presentation"
        data-testid="background-image"
        backgroundPosition={{ phone: '50% 15%', tablet: '0 top' }}
        css={{
          clipPath: isMobile
            ? 'ellipse(97% 95% at top)'
            : 'ellipse(100% 80% at left)',
        }}
      />
    </Box>
  );
};
