import { Image } from '@leagueplatform/genesis-commons';
import { StackLayout, ParagraphText } from '@leagueplatform/genesis-core';

interface DescriptionCardProps {
  description: string;
  image?: {
    src: string;
    width?: string;
    alt: string;
  };
}

export const DescriptionCard = ({
  description,
  image,
}: DescriptionCardProps) => (
  <StackLayout
    orientation={{
      '@initial': 'horizontal',
      '@mobile': 'vertical',
    }}
    verticalAlignment="center"
    horizontalAlignment="center"
    css={{ '@mobile': { paddingBlock: '$one' } }}
  >
    {image?.src && (
      <Image maxWidth={image.width} src={image.src} alt={image.alt} />
    )}
    <ParagraphText
      css={{
        paddingInline: '$three',
        '@mobile': {
          paddingInline: '$none',
          paddingBlockStart: '$three',
        },
      }}
    >
      {description}
    </ParagraphText>
  </StackLayout>
);
