import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  ParagraphText,
  Button,
  StackLayout,
  Modal,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { BaseModalContainer } from 'components/modals/base-modal.component';
import errorImg from 'assets/error.png';
import {
  CARE_ADVISOR_EMAIL,
  CARE_ADVISOR_PHONE_NUMBER,
  AUTH_VALIDATION_ERRORS,
} from 'common/constants';

type AuthErrorModalProps = {
  error: string;
  showModal: boolean;
  toggleModal: () => void;
};

type ErrorModalContent = {
  title: string;
  description: string;
  showEmailButton: boolean;
};

// Return the content for the error modal based on the error
const useErrorModalContent = (
  error: AuthErrorModalProps['error'],
): ErrorModalContent => {
  const { formatMessage } = useIntl();
  const errorText = (() => {
    switch (error) {
      case AUTH_VALIDATION_ERRORS.DELETED_USER:
        return {
          title: formatMessage({ id: 'DEACTIVATED_ACCOUNT_ERROR_TITLE' }),
        };
      case AUTH_VALIDATION_ERRORS.INELIGIBLE_USER:
        return {
          title: formatMessage({ id: 'NOT_ELIGIBLE_TO_ACCESS' }),
          description: formatMessage({
            id: 'REACH_OUT_TO_MEMBER_SERVICES_TEAM',
          }),
          showEmailButton: false,
        };
      default:
        return {};
    }
  })();

  return {
    title: formatMessage({ id: 'ERROR_HEADING_GENERIC' }),
    description: formatMessage({ id: 'ACCOUNT_ERROR_TEXT' }),
    showEmailButton: true,
    ...errorText,
  };
};

export const AuthErrorModal = ({
  error,
  showModal,
  toggleModal,
}: AuthErrorModalProps) => {
  const { formatMessage } = useIntl();
  const { title, description, showEmailButton } = useErrorModalContent(error);

  return (
    <Modal.Root
      open={showModal && !!error}
      onOpenChange={() => {
        if (showModal && error === AUTH_VALIDATION_ERRORS.INELIGIBLE_USER) {
          StandaloneAuth.client.logout();
        } else {
          toggleModal();
        }
      }}
    >
      <BaseModalContainer
        footer={
          <StackLayout
            horizontalAlignment="end"
            spacing="$one"
            orientation={{
              '@initial': 'horizontal',
              '@mobile': 'vertical',
            }}
            css={{
              marginBlockStart: '$one',
              flexGrow: 0,
            }}
          >
            {showEmailButton && (
              <Modal.Close>
                <Button
                  priority="secondary"
                  as="a"
                  href={`mailto:${CARE_ADVISOR_EMAIL}`}
                  width={{
                    '@initial': 'hugContents',
                    '@mobile': 'fillContainer',
                  }}
                >
                  {formatMessage({ id: 'SEND_AN_EMAIL' })}
                </Button>
              </Modal.Close>
            )}
            <Button
              disabled
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
            >{`${formatMessage({
              id: 'CALL',
            })} ${CARE_ADVISOR_PHONE_NUMBER}`}</Button>
          </StackLayout>
        }
      >
        <StackLayout
          horizontalAlignment="center"
          verticalAlignment="center"
          spacing="$half"
          css={{
            paddingRight: '$half',
            paddingLeft: '$half',
            textAlign: 'center',
          }}
        >
          <ImageMessage image={errorImg} imageWidth={146}>
            <Modal.Title>
              <HeadingText
                level="3"
                size="lg"
                css={{ marginBlockEnd: '$half' }}
              >
                {title}
              </HeadingText>
            </Modal.Title>
            <ParagraphText>{description}</ParagraphText>
          </ImageMessage>
        </StackLayout>
      </BaseModalContainer>
    </Modal.Root>
  );
};
