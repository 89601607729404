import React from 'react';
import { optumLiveChatExtension } from 'common/live-chat-extension';

export const CareDiscoveryRoutes = React.lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);

export const CareDocumentsRoutes = React.lazy(() =>
  import('@leagueplatform/care-documents').then((module) => ({
    default: module.CareDocumentsRoutes,
  })),
);

export const HealthJourneyRoutes = React.lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);

export const CareCollectionsRoutes = React.lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);

export const WalletRoutes = React.lazy(() =>
  import('@leagueplatform/wallet').then((module) => {
    // Config init is done here to stop the main App.tsx init from importing this SDK
    // which would cause the SDK to be bundled into the main chunk
    module.leagueWallet.config({
      showLandingPageHeaderBranding: true,
    });

    return { default: module.WalletRoutes };
  }),
);

export const MessagingRoutes = React.lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const FamilyExperienceRoutes = React.lazy(() =>
  import('@leagueplatform/family-experience').then((module) => ({
    default: module.FamilyExperienceRoutes,
  })),
);

export const Home = React.lazy(() =>
  import('pages/home/home.page').then((module) => ({
    default: module.Home,
  })),
);

export const LiveChatRoutes = React.lazy(() =>
  import('@leagueplatform/live-chat').then((module) => {
    module.leagueLiveChat.config({
      liveChatClient: optumLiveChatExtension,
    });
    return {
      default: module.LiveChatRoutes,
    };
  }),
);
