import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
  Button,
  ImageMessage,
  Modal,
} from '@leagueplatform/genesis-core';
import ERROR_IMAGE from 'assets/error.png';

export const CallbackStatusError = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <StackLayout
      verticalAlignment="center"
      horizontalAlignment="center"
      css={{ padding: '$one' }}
    >
      <ImageMessage
        image={ERROR_IMAGE}
        imageWidth={176}
        css={{ maxWidth: 327 }}
        imageGap="$three"
      >
        <StackLayout spacing="$half" horizontalAlignment="center">
          <HeadingText level="1" size="lg" css={{ textAlign: 'center' }}>
            {formatMessage({ id: 'CALLBACK_ERROR_HEADING' })}
          </HeadingText>
          <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
            {formatMessage({ id: 'CALLBACK_STATUS_ERROR_BODY' })}
          </ParagraphText>
          <Button
            css={{ marginTop: '$two' }}
            onClick={() => history.goBack()}
            width={{
              '@initial': 'hugContents',
              '@mobile': 'fillContainer',
            }}
          >
            {formatMessage({ id: 'GO_BACK' })}
          </Button>
        </StackLayout>
      </ImageMessage>
    </StackLayout>
  );
};

export const CallbackRequestError = ({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content
        css={{
          '.GDS-modal': {
            '@desktop': { minWidth: 583 },
            '@laptop': { minWidth: 583 },
          },
        }}
      >
        <ImageMessage image={ERROR_IMAGE} imageWidth={120}>
          <StackLayout spacing="$one" horizontalAlignment="stretch">
            <Modal.Title>
              <HeadingText level="1" size="xl" css={{ textAlign: 'center' }}>
                {formatMessage({ id: 'CALLBACK_ERROR_HEADING' })}
              </HeadingText>
            </Modal.Title>
            <ParagraphText css={{ textAlign: 'center' }}>
              {formatMessage({ id: 'CALLBACK_ERROR_BODY' })}
            </ParagraphText>
            <StackLayout horizontalAlignment="end">
              <Modal.Close>
                <Button
                  width={{
                    '@initial': 'hugContents',
                    '@mobile': 'fillContainer',
                  }}
                >
                  {formatMessage({ id: 'GO_BACK' })}
                </Button>
              </Modal.Close>
            </StackLayout>
          </StackLayout>
        </ImageMessage>
      </Modal.Content>
    </Modal.Root>
  );
};
