import * as React from 'react';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { Box } from '@leagueplatform/genesis-core';
import { AppNavBarComponent } from './navbar/nav.component';
import { APP_NAV_DEFAULT_HEIGHT } from './navbar/nav.constants';

interface Props {
  showNavigation?: boolean;
  height?: string;
  children: React.ReactNode;
}

export const BasePage = ({
  children,
  showNavigation = true,
  height = 'auto',
}: Props) => {
  useScrollTopOnLocationChange();

  return (
    <>
      {showNavigation && <AppNavBarComponent />}
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minHeight: `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`,
          flex: 1,
          height,
        }}
      >
        {children}
      </Box>
    </>
  );
};
