import { Flex } from '@leagueplatform/genesis-commons';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { LoadingSpinner } from './loading-spinner.component';

export const FullPageLoadingSpinner = () => (
  <FullScreenContainer>
    <Flex alignItems="center" height="100%" justifyContent="center">
      <LoadingSpinner />
    </Flex>
  </FullScreenContainer>
);
