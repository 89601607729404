import type { UseAuthReturn } from '@leagueplatform/auth-utils';

import {
  AGREEMENT_CONTENT_TYPES,
  REACCEPT_GINA_FLOW_STATUS,
} from 'common/constants';

export type AuthError =
  | UseAuthReturn['error']
  | {
      type?: string;
      description: string;
    };

export type AgreementStatus = {
  agreementType: string;
  isAccepted: true;
  acceptanceDate?: string;
};

// Return object values as a union type
export type AgreementContentTypes =
  (typeof AGREEMENT_CONTENT_TYPES)[keyof typeof AGREEMENT_CONTENT_TYPES];

export type ReacceptGINAFlowStatus =
  (typeof REACCEPT_GINA_FLOW_STATUS)[keyof typeof REACCEPT_GINA_FLOW_STATUS];

export const enum SENTRY_SEVERITY_LEVELS {
  ERROR = 'error',
}

type CommonLinkProps = {
  text: string;
  color?: string;
};

type InternalLink = CommonLinkProps & {
  to: string;
};
type ExternalLink = InternalLink & {
  target: string;
};
type ButtonLink = CommonLinkProps & {
  onClick: () => void;
};

export type Link = InternalLink | ExternalLink | ButtonLink;

export type PartnerUserIdentifiers = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  subscriberId: string;
  policyNumber: string;
  sourceSystemCode: string;
};

export type FeatureFlag = boolean | string;

export type PartnerUserCommunicationInfoResponse = {
  data: {
    attributes: PartnerUserCommunicationInfo;
    id: string;
    type: 'optum-partner-user-communication-info';
  };
};

export type PartnerUserCommunicationInfo = {
  email: string;
};
