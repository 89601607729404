import { useQuery } from 'react-query';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import camelcaseKeys from 'camelcase-keys';

export const GET_TBK_AUTH_MESSAGE_TYPE = 'authorize_tbk';

const getTBKAuth = async () =>
  SocketAsFetch.fetch({
    message_type: GET_TBK_AUTH_MESSAGE_TYPE,
  });

export const useGetTBKAuth = () =>
  useQuery([GET_TBK_AUTH_MESSAGE_TYPE], () => getTBKAuth(), {
    select: (data) => camelcaseKeys(data, { deep: true }),
  });
