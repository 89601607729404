import { CoverImage, ImageProps } from '@leagueplatform/genesis-commons';
import {
  Box,
  UtilityText,
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import { GinaContent } from 'models/gina-content.model';

interface Props {
  title?: string;
  subTitle: string;
  image?: ImageProps;
  content?: GinaContent[];
}

export const ConsentContent: React.FC<Props> = ({
  title,
  subTitle,
  image,
  content,
}: Props) => (
  <StackLayout
    orientation="vertical"
    horizontalAlignment="center"
    css={{
      margin: '0 auto',
      padding: '$one 0',
      width: '100%',
      maxWidth: '786px',
    }}
  >
    {title && (
      <HeadingText level="2" size="xl" css={{ padding: '$half' }}>
        {title}
      </HeadingText>
    )}
    {image?.src && (
      <StackLayout
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          padding: '$two',
        }}
      >
        <CoverImage src={image.src} alt={image.alt} size={image.width} />
      </StackLayout>
    )}
    <HeadingText level="3" size="xl" css={{ padding: '$half' }}>
      {subTitle}
    </HeadingText>
    <Box
      css={{
        color: '$onSurface.text.subdued',
        padding: '$half $half $six',
      }}
    >
      {content?.map((item) => {
        switch (item.type) {
          case 'paragraph':
            return (
              <ParagraphText size="sm" css={{ padding: '$half' }} key={item.id}>
                {item.content}
              </ParagraphText>
            );
          case 'heading':
            return (
              <HeadingText
                size="xs"
                level="4"
                key={item.id}
                css={{
                  padding: '$half',
                }}
              >
                {item.content}
              </HeadingText>
            );
          case 'listItems':
            return (
              <Box
                key={item.id}
                as="ul"
                css={{
                  margin: '0 $two',
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                }}
              >
                {(item?.content as string[])?.map((listItem, i) => (
                  <UtilityText
                    as="li"
                    size="sm"
                    key={`${item.id + i}`}
                    css={{
                      paddingBottom: '$half',
                    }}
                  >
                    <HtmlToReact htmlString={listItem} />
                  </UtilityText>
                ))}
              </Box>
            );
          case 'component':
            return item.content;
          default:
            return null;
        }
      })}
    </Box>
  </StackLayout>
);
