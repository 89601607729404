import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  MainPageContainer,
  Switch,
} from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import {
  HeadingText,
  Modal,
  Box,
  Divider,
  StackLayout,
  ParagraphText,
  UtilityText,
  TextAction,
  StackItem,
  useMediaQuery,
  queryHelpers,
  Spinner,
} from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { NotificationModal } from 'components/modals/notification-modal.component';
import {
  TERMS_OF_USE_ROUTE,
  PATHS,
  EPMP_DISPLAY_QUERY_PARAM,
} from 'common/constants';
import { BodyContainer } from 'components/body-container/body-container.component';
import { CheckConfirmation } from 'components/check-confirmation/check-confirmation.component';
import { CaptionWithLink } from 'components/caption-with-link.component/caption-with-link.component';
import { useCommunicationPreferences } from 'hooks/use-communication-preferences.hook';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';

const editEmailOnClickAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
    detail: 'edit email button',
  });

const otherPreferencesLinkOnClickAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
    detail: 'edit epmp preferences',
  });

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  const history = useHistory();
  const [
    {
      disableSms,
      allowEmailNotification,
      isLoading,
      isModalOpen,
      isWaiting,
      email,
      showEmail,
      isRefetching,
    },
    { toggleModal, setSmsNotification, setEmailNotification },
  ] = useCommunicationPreferences();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
  });

  const { featureFlags } = useFeatureFlags([FEATURE_FLAGS.EPMP_PREFERENCE]);
  const { [FEATURE_FLAGS.EPMP_PREFERENCE]: epmpIsEnabled } = featureFlags;

  const isAboveTablet = useMediaQuery(queryHelpers.up('tablet'));

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      {!epmpIsEnabled && (
        <BodyContainer css={{ maxWidth: '816px' }}>
          <CheckConfirmation
            label={formatMessage({
              id: 'COMMUNICATION_PREFERENCES_ALLOW_EMAIL',
            })}
            name="allowEmailNotification"
            checked={allowEmailNotification}
            handleCheck={setEmailNotification}
            loading={isLoading || isWaiting === 'email'}
            ariaDisabled={!!isWaiting}
          />
          <CheckConfirmation
            label={formatMessage({
              id: 'COMMUNICATION_PREFERENCES_ALLOW_TEXT',
            })}
            name="allowTextNotification"
            checked={!disableSms}
            handleCheck={setSmsNotification}
            loading={isLoading || isWaiting === 'sms'}
            ariaDisabled={!!isWaiting}
            hint={
              <ParagraphText size="xs">
                {formatMessage({ id: 'EMAIL_LEGAL_DISCLAIMER' })}
              </ParagraphText>
            }
          />
        </BodyContainer>
      )}
      {epmpIsEnabled && (
        <StackLayout
          orientation={isAboveTablet ? 'horizontal' : 'vertical'}
          spacing="$oneAndHalf"
        >
          <BodyContainer
            css={{
              paddingY: '$oneAndHalf',
              paddingX: '0',
            }}
          >
            <StackLayout
              orientation="vertical"
              spacing="$oneAndHalf"
              horizontalAlignment="stretch"
            >
              <HeadingText level="2" size="md" css={{ paddingX: '$two' }}>
                {formatMessage({ id: 'APP_NOTIFICATIONS' })}
              </HeadingText>
              <StackLayout
                orientation="vertical"
                spacing={showEmail ? '$half' : '$none'}
                horizontalAlignment="stretch"
              >
                <StackLayout
                  orientation="horizontal"
                  spacing="$one"
                  horizontalAlignment="spaceBetween"
                  css={{ paddingX: '$oneAndHalf' }}
                >
                  <Switch
                    id="communication-preference-email-notification"
                    checked={!!allowEmailNotification}
                    onClick={() => {
                      if (setEmailNotification) {
                        setEmailNotification();
                      }
                    }}
                    label={
                      <StackLayout orientation="vertical" spacing="$half">
                        <UtilityText emphasis="emphasized" size="sm">
                          {formatMessage({ id: 'ALLOW_EMAIL_NOTIFICATIONS' })}
                        </UtilityText>
                        <ParagraphText size="xs">
                          {formatMessage({ id: 'RECEIVE_OCCASIONAL_EMAILS' })}
                        </ParagraphText>
                      </StackLayout>
                    }
                    layout="row"
                    hideIcon
                    ariaDisabled={!!isWaiting}
                    loading={isLoading || isWaiting === 'email'}
                    css={{
                      '.cc-switch-label': {
                        'align-items': 'start',
                      },
                    }}
                  />
                </StackLayout>
                <Box aria-live="polite">
                  {showEmail && (
                    <Box css={{ paddingX: '$two' }}>
                      <StackLayout
                        orientation="horizontal"
                        verticalAlignment="bottom"
                        horizontalAlignment="spaceBetween"
                        css={{
                          '@mobile': { flexWrap: 'wrap' },
                        }}
                        spacing="$half"
                      >
                        <ParagraphText css={{ '&&': { marginBlockEnd: 0 } }}>
                          <UtilityText
                            emphasis="subtle"
                            size="xs"
                            css={{ fontWeight: 'bold' }}
                          >
                            {formatMessage({
                              id: 'USER_NOTIFIED_EMAIL_SUBTITLE',
                            })}
                          </UtilityText>
                          <br />
                          {isRefetching ? (
                            <Spinner loading size="$one" />
                          ) : (
                            <UtilityText size="sm">{email}</UtilityText>
                          )}
                        </ParagraphText>
                        <TextAction
                          as="a"
                          indicator="externalLink"
                          target="_blank"
                          href={`${PATHS.EPMP}?display=${EPMP_DISPLAY_QUERY_PARAM.CONTACT}`}
                          size="medium"
                          aria-label={formatMessage({ id: 'EDIT_EMAIL' })}
                          onClick={editEmailOnClickAnalyticsEvent}
                        >
                          {formatMessage({ id: 'EDIT' })}
                        </TextAction>
                      </StackLayout>
                    </Box>
                  )}
                </Box>
              </StackLayout>
              <Box css={{ paddingX: '$two' }}>
                <Divider />
                <CaptionWithLink
                  messageId="EMAIL_LEGAL_DISCLAIMER"
                  linkHref={TERMS_OF_USE_ROUTE}
                  linkTextId="VISIT_OUR_TERMS"
                  css={{ color: '$onSurfaceTextSubdued' }}
                />
              </Box>
            </StackLayout>
          </BodyContainer>
          <StackItem
            shrinkFactor={0}
            baseWidth={isAboveTablet ? '322px' : 'auto'}
            verticalAlignment="top"
          >
            <BodyContainer
              css={{
                marginLeft: 0,
              }}
            >
              <HeadingText
                level="2"
                size="md"
                css={{ marginBottom: '$oneAndHalf' }}
              >
                {formatMessage({ id: 'OTHER_PREFERENCES_HEADING' })}
              </HeadingText>
              <StackLayout as="ul">
                <Box as="li">
                  <TextAction
                    size="medium"
                    icon="statusAlert"
                    indicator="externalLink"
                    href={`${PATHS.EPMP}?display=${EPMP_DISPLAY_QUERY_PARAM.PREFERENCES}`}
                    target="_blank"
                    onClick={otherPreferencesLinkOnClickAnalyticsEvent}
                    css={{
                      '.GDS-icon': {
                        flexShrink: 0,
                      },
                    }}
                    aria-describedby="other-preferences-link-description"
                  >
                    <Box as="span" css={{ paddingLeft: '$quarter' }}>
                      {formatMessage({ id: 'OTHER_PREFERENCES_LINK' })}
                    </Box>
                  </TextAction>
                  <ParagraphText
                    id="other-preferences-link-description"
                    size="xs"
                    emphasis="subtle"
                    css={{ marginTop: '$half' }}
                  >
                    {formatMessage({
                      id: 'OTHER_PREFERENCES_LINK_DESCRIPTION',
                    })}
                  </ParagraphText>
                </Box>
              </StackLayout>
            </BodyContainer>
          </StackItem>
        </StackLayout>
      )}
      <Modal.Root open={isModalOpen} onOpenChange={toggleModal}>
        <NotificationModal
          submitButtonText={formatMessage({ id: 'OKAY' })}
          headingText={formatMessage({ id: 'N0_PHONE_NUMBER_ON_FILE' })}
          description={formatMessage({
            id: 'NO_PHONE_NUMBER_ON_FILE_DESCRIPTION',
          })}
        />
      </Modal.Root>
    </MainPageContainer>
  );
};
